import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaChartLine, FaFileAlt, FaLightbulb } from 'react-icons/fa';
import axios from '../utils/axios';
import { Bar, Doughnut } from 'react-chartjs-2';
import { checkAuth } from '../utils/auth';
import { useLanguage } from '../LanguageContext';
import Sidebar from './Sidebar';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import './MonitoringInsights.css';
import Footer from './Footer';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const MonitoringInsights = () => {
  const navigate = useNavigate();
  const { translate } = useLanguage();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeItem, setActiveItem] = useState(translate('monitoring.insights'));
  const [impactData, setImpactData] = useState({
    labels: [
      translate('monitoring.critical'),
      translate('monitoring.high'),
      translate('monitoring.medium'),
      translate('monitoring.low')
    ],
    datasets: [{
      label: translate('monitoring.filters.impact_level.label'),
      data: [0, 0, 0, 0],
      backgroundColor: [
        '#dc3545',
        '#fd7e14',
        '#ffc107',
        '#28a745'
      ],
      borderWidth: 0,
      hoverOffset: 4
    }]
  });
  
  const [vulnerabilityData, setVulnerabilityData] = useState({
    labels: [],
    datasets: [{
      label: translate('monitoring.vulnerability_types.web'),
      data: [],
      backgroundColor: '#4e73df',
      borderWidth: 0,
      borderRadius: 4,
      maxBarThickness: 30
    }]
  });

  useEffect(() => {
    const fetchInsights = async () => {
      try {
        setLoading(true);
        setError(null);
        
        const { isAuthenticated, token, companyUuid } = checkAuth();
        
        if (!isAuthenticated || !token || !companyUuid) {
          navigate('/auth/signin');
          return;
        }

        const response = await axios.get(
          `/monitoring/insights/${companyUuid}`
        );

        if (response.data) {
          // Atualiza dados de impacto
          const impactStats = response.data.impactStats || {
            critical: 0,
            high: 0,
            medium: 0,
            low: 0
          };

          setImpactData({
            labels: [
              translate('monitoring.critical'),
              translate('monitoring.high'),
              translate('monitoring.medium'),
              translate('monitoring.low')
            ],
            datasets: [{
              label: translate('monitoring.filters.impact_level.label'),
              data: [
                impactStats.critical || 0,
                impactStats.high || 0,
                impactStats.medium || 0,
                impactStats.low || 0
              ],
              backgroundColor: [
                '#dc3545',
                '#fd7e14',
                '#ffc107',
                '#28a745'
              ],
              borderWidth: 0,
              hoverOffset: 4
            }]
          });

          // Atualiza dados de tipos de vulnerabilidade
          if (response.data.typeStats) {
            // Ordena os tipos por quantidade (do maior para o menor)
            const sortedTypes = Object.entries(response.data.typeStats)
              .sort(([,a], [,b]) => b - a)
              .slice(0, 5); // Pega apenas os 5 mais frequentes

            const vulnLabels = sortedTypes.map(([type]) => type);
            const vulnData = sortedTypes.map(([,count]) => count);

            setVulnerabilityData({
              labels: vulnLabels,
              datasets: [{
                label: translate('monitoring.vulnerability_types.web'),
                data: vulnData,
                backgroundColor: '#4e73df',
                borderWidth: 0,
                borderRadius: 4,
                maxBarThickness: 30
              }]
            });
          }
        }
      } catch (error) {
        console.error('Erro ao buscar insights:', error);
        setError(error.response?.data?.error || translate('monitoring.error_loading'));
        if (error.response?.status === 401) {
          navigate('/auth/signin');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchInsights();
  }, [navigate, translate]);

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '70%',
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: 'rgba(0,0,0,0.8)',
        padding: 12,
        titleFont: {
          size: 14,
          weight: 'bold'
        },
        bodyFont: {
          size: 13
        },
        callbacks: {
          label: function(context) {
            const value = context.raw;
            const total = context.dataset.data.reduce((a, b) => a + b, 0);
            const percentage = total > 0 ? Math.round((value / total) * 100) : 0;
            return `${value} (${percentage}%)`;
          }
        }
      }
    }
  };

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: 'rgba(0,0,0,0.8)',
        padding: 12,
        titleFont: {
          size: 14,
          weight: 'bold'
        },
        bodyFont: {
          size: 13
        }
      }
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(0,0,0,0.05)',
          drawBorder: false
        },
        ticks: {
          font: {
            size: 12
          }
        },
        beginAtZero: true
      },
      y: {
        grid: {
          display: false
        },
        ticks: {
          font: {
            size: 12,
            weight: '500'
          },
          maxRotation: 0,
          minRotation: 0,
          padding: 10
        }
      }
    },
    layout: {
      padding: {
        left: 10,
        right: 30,
        top: 0,
        bottom: 0
      }
    },
    maintainAspectRatio: false,
    aspectRatio: 0.95
  };

  return (
    <div className="monitoring-container">
      <Sidebar activeItem={activeItem} />
      <div className="content">
        <div className="insights-container">
          <h2>{translate('monitoring.insights')}</h2>
          
          {loading ? (
            <div className="loading">
              <div className="spinner"></div>
              <span>{translate('monitoring.loading')}</span>
            </div>
          ) : error ? (
            <div className="error-message">
              <p>{error}</p>
            </div>
          ) : (
            <div className="insights-cards">
              <div className="insight-card">
                <div className="card-header">
                  <h3>{translate('monitoring.impact_distribution')}</h3>
                  <span className="total-count">
                    {translate('monitoring.total')}: {impactData.datasets[0].data.reduce((a, b) => a + b, 0)}
                  </span>
                </div>
                <div className="chart-wrapper">
                  <Doughnut data={impactData} options={doughnutOptions} />
                </div>
                <div className="legend">
                  <div className="legend-item">
                    <span className="dot critical"></span>
                    <span className="label">{translate('monitoring.critical')}</span>
                    <span className="value">{impactData.datasets[0].data[0]}</span>
                  </div>
                  <div className="legend-item">
                    <span className="dot high"></span>
                    <span className="label">{translate('monitoring.high')}</span>
                    <span className="value">{impactData.datasets[0].data[1]}</span>
                  </div>
                  <div className="legend-item">
                    <span className="dot medium"></span>
                    <span className="label">{translate('monitoring.medium')}</span>
                    <span className="value">{impactData.datasets[0].data[2]}</span>
                  </div>
                  <div className="legend-item">
                    <span className="dot low"></span>
                    <span className="label">{translate('monitoring.low')}</span>
                    <span className="value">{impactData.datasets[0].data[3]}</span>
                  </div>
                </div>
              </div>

              <div className="insight-card">
                <div className="card-header">
                  <h3>{translate('monitoring.top_vulnerabilities')}</h3>
                </div>
                <div className="chart-wrapper">
                  <Bar data={vulnerabilityData} options={barOptions} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MonitoringInsights; 