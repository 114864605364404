import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../utils/axios';
import './Login.css';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { useLanguage } from '../LanguageContext';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { setLanguage, translate } = useLanguage();

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setIsEmailValid(validateEmail(emailValue));
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(''); // Limpa mensagem de erro anterior
    
    try {
      console.log('Iniciando login...');
      const response = await axios.post('/auth/login', {
        email,
        password
      });

      console.log('Resposta do login:', response.data);

      const { 
        token, 
        companyUuid, 
        companyName, 
        userName, 
        userUuid, 
        languagePreference,
        companies 
      } = response.data;
      
      // Salva os dados no localStorage
      localStorage.setItem('token', token);
      localStorage.setItem('userName', userName);
      localStorage.setItem('languagePreference', languagePreference);

      // Atualiza o idioma
      setLanguage(languagePreference);
      
      // Se vier companyUuid na resposta, significa que o usuário tem apenas uma empresa
      if (companyUuid) {
        console.log('Empresa encontrada na resposta, indo para home...');
        if (companyName) {
          localStorage.setItem('companyName', companyName);
        }
        // Verifica se há uma rota anterior para redirecionar
        const from = location.state?.from?.pathname || '/home';
        navigate(from);
        return;
      }

      // Se não vier companyUuid mas vier companies, significa que o usuário precisa selecionar uma empresa
      if (companies) {
        console.log('Usuário tem múltiplas empresas, indo para seleção...');
        navigate('/select-company', { 
          state: { 
            companies,
            userUuid,
            from: location.state?.from?.pathname
          }
        });
        return;
      }

      // Se não vier nem companyUuid nem companies, algo está errado
      console.error('Nenhuma empresa associada ao usuário');
      setErrorMessage('Usuário não está associado a nenhuma empresa');
      
    } catch (error) {
      console.error('Erro ao fazer login:', error);
      setErrorMessage(error.response?.data?.error || 'Erro ao fazer login');
    }
  };

  useEffect(() => {
    const detectUserLanguage = async () => {
      try {
        // Verifica se já existe uma preferência salva
        const savedLanguage = localStorage.getItem('languagePreference');
        if (savedLanguage) {
          setLanguage(savedLanguage);
          return;
        }

        // Detecta o idioma baseado no IP
        const response = await axios.get('https://ipapi.co/json/');
        const countryCode = response.data.country_code.toLowerCase();
        
        // Define o idioma baseado no país
        let detectedLanguage = 'en'; // Padrão inglês
        if (countryCode === 'br') {
          detectedLanguage = 'pt-br';
        }
        
        // Salva e define o idioma detectado
        localStorage.setItem('languagePreference', detectedLanguage);
        setLanguage(detectedLanguage);
        
        console.log('Idioma detectado:', detectedLanguage);
      } catch (error) {
        console.error('Erro ao detectar idioma:', error);
        // Em caso de erro, usa inglês como padrão
        setLanguage('en');
      }
    };

    detectUserLanguage();
  }, [setLanguage]);

  return (
    <div className="login-page">
      <div className="image-container">
        <img src="/assets/login.jpg" alt="Imagem de Fundo" className="background-image" />
      </div>
      <div className="login-container">
        <div className="login-card">
          <img src="/assets/wehack-logo.png" alt="Wehack Logo" className="logo" />
          <h2>{translate('login.title')}</h2>
          {errorMessage && <div className="error-toast">{errorMessage}</div>}
          <form className="login-form" onSubmit={handleSubmit}>
            <div className="input-group">
              <input
                type="email"
                placeholder={translate('login.email_placeholder')}
                value={email}
                onChange={handleEmailChange}
                required
              />
              {isEmailValid === false && <FaTimesCircle color="red" />}
              {isEmailValid === true && <FaCheckCircle color="green" />}
            </div>
            <div className="input-group">
              <input
                type="password"
                placeholder={translate('login.password_placeholder')}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="button" className="forgot-password" onClick={() => window.location.href = 'mailto:contact@wehack.com.br?subject=Esqueci%20minha%20senha'}>{translate('login.forgot_password')}</button>
            <button type="submit" className="login-button">{translate('login.submit')}</button>
          </form>
          <br></br>
          <p>{translate('login.no_account')} <button type="button" className="contact-us" onClick={() => window.location.href = 'mailto:contact@wehack.com.br?subject=Contato'}>{translate('login.contact_us')}</button></p>
          <footer>© 2025 Wehack. Todos os direitos reservados.</footer>
        </div>
      </div>
    </div>
  );
}

export default Login;
