import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../utils/axios';
import './CompanySelect.css';
import { useLanguage } from '../LanguageContext';
import { FaBuilding, FaCog, FaChevronDown, FaGlobe } from 'react-icons/fa';
import brFlag from '../assets/flags/br.png';
import usFlag from '../assets/flags/us.png';
import ptFlag from '../assets/flags/pt.png';
import gerFlag from '../assets/flags/ger.png';
import esFlag from '../assets/flags/es.png';
import frFlag from '../assets/flags/fr.png';
import itFlag from '../assets/flags/it.png';
import jaFlag from '../assets/flags/ja.png';
import zhFlag from '../assets/flags/zh.png';

const CompanySelect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { translate, currentLanguage, setLanguage } = useLanguage();
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);

  if (!location.state?.companies || !location.state?.userUuid) {
    console.log('Dados necessários não encontrados no state, redirecionando para login');
    navigate('/auth/signin');
    return null;
  }

  const { companies, userUuid } = location.state;

  const handleCompanySelect = async (companyUuid) => {
    try {
      const response = await axios.post('/auth/select-company', {
        userUuid,
        companyUuid
      });
      
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        const from = location.state?.from || '/home';
        navigate(from);
      }
    } catch (error) {
      console.error('Erro ao selecionar empresa:', error);
    }
  };

  const toggleLanguageDropdown = () => {
    setIsLanguageDropdownOpen(!isLanguageDropdownOpen);
  };

  const handleLanguageSelect = (lang) => {
    setLanguage(lang);
    setIsLanguageDropdownOpen(false);
  };

  const getLanguageFlag = (language) => {
    switch (language) {
      case 'pt-br':
        return brFlag;
      case 'pt-pt':
        return ptFlag;
      case 'en':
        return usFlag;
      case 'de':
        return gerFlag;
      case 'es':
        return esFlag;
      case 'fr':
        return frFlag;
      case 'it':
        return itFlag;
      case 'ja':
        return jaFlag;
      case 'zh':
        return zhFlag;
      default:
        return brFlag;
    }
  };

  return (
    <div className="company-select-page">
      <div className="language-selector" onClick={toggleLanguageDropdown}>
        <div className="language-current">
          <FaGlobe className="language-icon" />
          <img 
            src={getLanguageFlag(currentLanguage)} 
            alt="Flag" 
            className="flag-icon"
          />
          <FaChevronDown className={`chevron-icon ${isLanguageDropdownOpen ? 'open' : ''}`} />
        </div>
        {isLanguageDropdownOpen && (
          <div className="language-dropdown">
            <div 
              className="language-option" 
              onClick={() => handleLanguageSelect('pt-br')}
            >
              <img src={brFlag} alt="BR" className="flag-icon" />
              Português (Brasil)
            </div>
            <div 
              className="language-option" 
              onClick={() => handleLanguageSelect('pt-pt')}
            >
              <img src={ptFlag} alt="PT" className="flag-icon" />
              Português (Portugal)
            </div>
            <div 
              className="language-option" 
              onClick={() => handleLanguageSelect('de')}
            >
              <img src={gerFlag} alt="DE" className="flag-icon" />
              Deutsch
            </div>
            <div 
              className="language-option" 
              onClick={() => handleLanguageSelect('es')}
            >
              <img src={esFlag} alt="ES" className="flag-icon" />
              Español
            </div>
            <div 
              className="language-option" 
              onClick={() => handleLanguageSelect('fr')}
            >
              <img src={frFlag} alt="FR" className="flag-icon" />
              Français
            </div>
            <div 
              className="language-option" 
              onClick={() => handleLanguageSelect('it')}
            >
              <img src={itFlag} alt="IT" className="flag-icon" />
              Italiano
            </div>
            <div 
              className="language-option" 
              onClick={() => handleLanguageSelect('ja')}
            >
              <img src={jaFlag} alt="JA" className="flag-icon" />
              日本語
            </div>
            <div 
              className="language-option" 
              onClick={() => handleLanguageSelect('zh')}
            >
              <img src={zhFlag} alt="ZH" className="flag-icon" />
              中文
            </div>
            <div 
              className="language-option" 
              onClick={() => handleLanguageSelect('en')}
            >
              <img src={usFlag} alt="US" className="flag-icon" />
              English
            </div>
          </div>
        )}
      </div>

      <div className="company-select-container">
        <div className="company-select-header">
          <img src="/assets/wehack-logo.png" alt="Wehack Logo" />
          <h2>{translate('company_select.title')}</h2>
          <p>{translate('company_select.subtitle')}</p>
        </div>
        
        <div className="companies-list">
          {companies.map((company) => (
            <div
              key={company.uuid}
              className="company-item"
            >
              <div className="company-main" onClick={() => handleCompanySelect(company.uuid)}>
                <div className="company-icon">
                  <FaBuilding />
                </div>
                <div className="company-info">
                  <h3 className="company-name">{company.name}</h3>
                  <p className="company-id">{translate('company_select.company_id')}: {company.uuid}</p>
                </div>
              </div>
              <button className="manage-account-btn">
                <FaCog />
                {translate('company_select.manage_account')}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CompanySelect; 