import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axios';
import { checkAuth } from '../utils/auth';
import { useLanguage } from '../LanguageContext';
import { FaGlobe, FaMobile, FaCode } from 'react-icons/fa';
import { RiComputerLine, RiSmartphoneLine, RiTerminalBoxLine, RiShieldKeyholeFill, RiShieldFlashFill, RiShieldKeyholeLine } from 'react-icons/ri';
import './Applications.css';
import Footer from './Footer';

const CustomSelect = ({ label, options, value, onChange, name }) => {
  const { translate } = useLanguage();
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelect = (newValue) => {
    onChange({ target: { name, value: newValue } });
    setIsOpen(false);
  };

  const formatDisplayText = (text) => {
    // Converte 'web application' para 'Web Application'
    // Converte 'black-box' para 'Black Box'
    return text
      .split('-')
      .join(' ')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const getIcon = (type) => {
    switch (type) {
      // Tipo de Aplicação
      case 'web application':
        return <RiComputerLine />;
      case 'mobile application':
        return <RiSmartphoneLine />;
      case 'api':
        return <RiTerminalBoxLine />;
      // Tipo de Operação
      case 'black-box':
        return <RiShieldKeyholeFill />;
      case 'grey-box':
        return <RiShieldFlashFill />;
      case 'white-box':
        return <RiShieldKeyholeLine />;
      default:
        return null;
    }
  };

  const getTranslatedValue = (value) => {
    if (value.includes('application')) {
      return translate(`applications.types.${value === 'web application' ? 'web' : value === 'mobile application' ? 'mobile' : 'api'}`);
    } else if (value.includes('box')) {
      return translate(`applications.operations.${value.replace('-', '')}`);
    }
    return value;
  };

  return (
    <div className="form-group">
      <label>{translate(label)}</label>
      <div className="custom-select" ref={selectRef}>
        <div 
          className="select-selected" 
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="select-value">
            <span className="select-icon">{getIcon(value)}</span>
            <span>{getTranslatedValue(value)}</span>
          </div>
        </div>
        {isOpen && (
          <div className="select-options">
            {options.map((option) => (
              <div
                key={option.value}
                className={`select-option ${value === option.value ? 'selected' : ''}`}
                onClick={() => handleSelect(option.value)}
              >
                <span className="select-icon">{getIcon(option.value)}</span>
                <span>{option.label}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const Applications = () => {
  const navigate = useNavigate();
  const { translate } = useLanguage();
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const applicationsPerPage = 5;
  const [newApplication, setNewApplication] = useState({
    host: '',
    application_type: 'web application',
    operation_type: 'black-box'
  });
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowModal(false);
      }
    };

    if (showModal) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showModal]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewApplication(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { companyUuid } = checkAuth();
      const response = await axios.post(`/applications/company/${companyUuid}`, newApplication);
      
      if (response.data.application) {
        setApplications(prev => [...prev, response.data.application]);
        setShowModal(false);
        setNewApplication({
          host: '',
          application_type: 'web application',
          operation_type: 'black-box'
        });
      }
    } catch (error) {
      console.error('Erro ao criar aplicação:', error);
    }
  };

  const getApplicationIcon = (type) => {
    switch (type) {
      case 'web application':
        return (
          <div className="icon-container web-app">
            <RiComputerLine />
          </div>
        );
      case 'mobile application':
        return (
          <div className="icon-container mobile-app">
            <RiSmartphoneLine />
          </div>
        );
      case 'api':
        return (
          <div className="icon-container api-app">
            <RiTerminalBoxLine />
          </div>
        );
      default:
        return (
          <div className="icon-container web-app">
            <RiComputerLine />
          </div>
        );
    }
  };

  const getFaviconUrl = (host) => {
    try {
      // Remove protocolo e path, deixando apenas o domínio
      const domain = host.replace(/^https?:\/\//, '').split('/')[0];
      
      // Array com diferentes formatos de URL para favicon
      return [
        `https://www.google.com/s2/favicons?domain=${domain}&sz=64`,
        `https://icon.horse/icon/${domain}`,
        `https://favicons.githubusercontent.com/${domain}`,
        `https://${domain}/favicon.ico`,
        `https://${domain}/favicon.png`,
        `https://${domain}/apple-touch-icon.png`,
        `https://${domain}/apple-touch-icon-precomposed.png`
      ];
    } catch (error) {
      console.error('Erro ao gerar URLs de favicon:', error);
      return [];
    }
  };

  const handleFaviconError = (e) => {
    const img = e.target;
    const host = img.getAttribute('data-host');
    const urls = getFaviconUrl(host);
    const currentIndex = urls.indexOf(img.src);
    
    if (currentIndex < urls.length - 1) {
      // Tenta a próxima URL da lista
      img.src = urls[currentIndex + 1];
    } else {
      // Se todas as tentativas falharem, mostra o ícone padrão
      img.style.display = 'none';
      const iconContainer = img.parentElement;
      if (iconContainer) {
        const defaultIcon = document.createElement('i');
        defaultIcon.className = 'fas fa-globe';
        defaultIcon.style.fontSize = '16px';
        defaultIcon.style.color = '#6c757d';
        iconContainer.appendChild(defaultIcon);
      }
    }
  };

  const getOperationIcon = (type) => {
    const getTooltipText = (type) => {
      switch (type) {
        case 'black-box':
          return 'Teste sem acesso ao código fonte, simulando um ataque externo';
        case 'grey-box':
          return 'Teste com acesso parcial ao código fonte e documentação';
        case 'white-box':
          return 'Teste com acesso completo ao código fonte e documentação';
        default:
          return '';
      }
    };

    switch (type) {
      case 'black-box':
        return (
          <div className="operation-icon-container black-box" title={getTooltipText(type)}>
            <RiShieldKeyholeFill />
          </div>
        );
      case 'grey-box':
        return (
          <div className="operation-icon-container grey-box" title={getTooltipText(type)}>
            <RiShieldFlashFill />
          </div>
        );
      case 'white-box':
        return (
          <div className="operation-icon-container white-box" title={getTooltipText(type)}>
            <RiShieldKeyholeLine />
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const { isAuthenticated, token, companyUuid } = checkAuth();
        console.log('Company UUID:', companyUuid);

        if (!isAuthenticated) {
          navigate('/auth/signin');
          return;
        }

        const response = await axios.get(`/applications/company/${companyUuid}`);
        console.log('API Response:', response.data);
        
        if (response.data && Array.isArray(response.data)) {
          setApplications(response.data);
        } else if (response.data && Array.isArray(response.data.applications)) {
          setApplications(response.data.applications);
        } else {
          console.error('Formato de resposta inesperado:', response.data);
          setApplications([]);
        }
        
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar aplicações:', error);
        if (error.response) {
          console.error('Erro da API:', error.response.data);
        }
        if (error.response?.status === 401) {
          navigate('/auth/signin');
        }
        setLoading(false);
      }
    };

    fetchApplications();
  }, [navigate]);

  const getStatusClass = (status) => {
    switch (status?.toLowerCase()) {
      case 'active':
        return 'active';
      case 'inactive':
        return 'inactive';
      case 'monitoring':
        return 'monitoring';
      default:
        return '';
    }
  };

  const formatDisplayText = (text) => {
    return text
      .split('-')
      .join(' ')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const getTooltipText = (type) => {
    switch (type) {
      case 'black-box':
        return translate('applications.tooltips.blackBox');
      case 'grey-box':
        return translate('applications.tooltips.greyBox');
      case 'white-box':
        return translate('applications.tooltips.whiteBox');
      default:
        return '';
    }
  };

  const getApplicationTypeTranslation = (type) => {
    switch (type) {
      case 'web application':
        return translate('applications.types.web');
      case 'mobile application':
        return translate('applications.types.mobile');
      case 'api':
        return translate('applications.types.api');
      default:
        return type;
    }
  };

  const getOperationTypeTranslation = (type) => {
    switch (type) {
      case 'black-box':
        return translate('applications.operations.blackbox');
      case 'grey-box':
        return translate('applications.operations.greybox');
      case 'white-box':
        return translate('applications.operations.whitebox');
      default:
        return type;
    }
  };

  // Lógica de paginação
  const indexOfLastApplication = currentPage * applicationsPerPage;
  const indexOfFirstApplication = indexOfLastApplication - applicationsPerPage;
  const currentApplications = applications.slice(indexOfFirstApplication, indexOfLastApplication);
  const totalPages = Math.ceil(applications.length / applicationsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (loading) {
    return (
      <div className="applications-container">
        <div className="loading">{translate('common.loading')}</div>
      </div>
    );
  }

  return (
    <div className="applications-container">
      <h2>{translate('menu.applications')}</h2>
      <div className="button-container">
        <button className="add-application-btn" onClick={() => setShowModal(true)}>
          {translate('applications.add')}
        </button>
      </div>
      
      {showModal && (
        <div className="modal-overlay" onClick={(e) => {
          if (e.target === e.currentTarget) {
            setShowModal(false);
          }
        }}>
          <div className="modal-content" ref={modalRef}>
            <h3>{translate('applications.modal.title')}</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>{translate('applications.form.host')}</label>
                <input
                  type="text"
                  name="host"
                  value={newApplication.host}
                  onChange={handleInputChange}
                  placeholder={translate('applications.form.hostPlaceholder')}
                  required
                />
              </div>

              <CustomSelect
                label="applications.form.applicationType"
                name="application_type"
                value={newApplication.application_type}
                onChange={handleInputChange}
                options={[
                  { value: 'web application', label: translate('applications.types.web') },
                  { value: 'mobile application', label: translate('applications.types.mobile') },
                  { value: 'api', label: translate('applications.types.api') }
                ]}
              />

              <CustomSelect
                label="applications.form.operationType"
                name="operation_type"
                value={newApplication.operation_type}
                onChange={handleInputChange}
                options={[
                  { value: 'black-box', label: translate('applications.operations.blackbox') },
                  { value: 'grey-box', label: translate('applications.operations.greybox') },
                  { value: 'white-box', label: translate('applications.operations.whitebox') }
                ]}
              />

              <div className="modal-actions">
                <button type="button" className="cancel-btn" onClick={() => setShowModal(false)}>
                  {translate('common.cancel')}
                </button>
                <button type="submit" className="submit-btn">
                  {translate('common.save')}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="applications-table">
        <table>
          <thead>
            <tr>
              <th>{translate('applications.table.application')}</th>
              <th>{translate('applications.table.host')}</th>
              <th>{translate('applications.table.operation')}</th>
              <th>{translate('applications.table.monitoring')}</th>
            </tr>
          </thead>
          <tbody>
            {currentApplications.map((app, index) => (
              <tr key={index}>
                <td>
                  <div className="application-type">
                    {getApplicationIcon(app.application_type)}
                    <span>{getApplicationTypeTranslation(app.application_type)}</span>
                  </div>
                </td>
                <td>
                  <div className="host-container">
                    <img 
                      src={getFaviconUrl(app.host)[0]} 
                      alt="" 
                      className="host-favicon"
                      data-host={app.host}
                      onError={handleFaviconError}
                    />
                    <span>{app.host}</span>
                  </div>
                </td>
                <td>
                  <div className="operation-type">
                    {getOperationIcon(app.operation_type)}
                    <span>{getOperationTypeTranslation(app.operation_type)}</span>
                  </div>
                </td>
                <td>
                  <span className={`status ${getStatusClass(app.status)}`}>
                    {translate(`applications.status.${app.status?.toLowerCase()}`)}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        
        <div className="pagination">
          <button 
            onClick={() => handlePageChange(currentPage - 1)} 
            disabled={currentPage === 1}
          >
            &lt;
          </button>
          <span>{currentPage} de {totalPages}</span>
          <button 
            onClick={() => handlePageChange(currentPage + 1)} 
            disabled={currentPage === totalPages}
          >
            &gt;
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Applications; 