import axios from 'axios';

// Configuração do axios
const instance = axios.create({
  // Em produção será '', em dev será '/api'
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 30000, // 30 segundos
  timeoutErrorMessage: 'Tempo de requisição excedido. Por favor, tente novamente.',
  maxRetries: 3,
  retryDelay: 1000
});

// Interceptor para adicionar o token de autenticação
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Força HTTPS em produção
    if (process.env.NODE_ENV === 'production' && config.url) {
      config.url = config.url.replace('http://', 'https://');
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor para tratar erros e fazer retry
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config } = error;
    
    // Se não é uma requisição que pode ser retentada
    if (!config || !config.maxRetries) {
      return Promise.reject(error);
    }

    config.retryCount = config.retryCount || 0;

    // Se ainda não atingiu o número máximo de tentativas
    if (config.retryCount < config.maxRetries) {
      config.retryCount += 1;

      // Delay antes de tentar novamente
      await new Promise(resolve => setTimeout(resolve, config.retryDelay));

      return instance(config);
    }

    return Promise.reject(error);
  }
);

export default instance; 