// Função para decodificar o token JWT
const decodeToken = (token) => {
  try {
    console.log('Tentando decodificar token:', token.substring(0, 20) + '...');
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error('Erro ao decodificar token:', error);
    return null;
  }
};

export const checkAuth = () => {
  console.log('Verificando autenticação...');
  const token = localStorage.getItem('token');
  if (!token) {
    console.log('Nenhum token encontrado no localStorage');
    return { isAuthenticated: false, token: null, companyUuid: null };
  }

  const decodedToken = decodeToken(token);
  console.log('Token decodificado:', decodedToken);

  if (!decodedToken || !decodedToken.companyUuid) {
    console.log('Token inválido ou sem companyUuid:', { decodedToken });
    localStorage.removeItem('token'); // Remove token inválido
    return { isAuthenticated: false, token: null, companyUuid: null };
  }

  // Verifica se o token expirou
  const currentTime = Date.now() / 1000;
  console.log('Verificando expiração do token:', {
    currentTime,
    tokenExp: decodedToken.exp,
    diferenca: decodedToken.exp - currentTime
  });
  
  if (decodedToken.exp && decodedToken.exp < currentTime) {
    console.log('Token expirado');
    localStorage.removeItem('token'); // Remove token expirado
    return { isAuthenticated: false, token: null, companyUuid: null };
  }

  console.log('Autenticação bem-sucedida:', {
    companyUuid: decodedToken.companyUuid,
    role: decodedToken.role
  });

  return { 
    isAuthenticated: true, 
    token, 
    companyUuid: decodedToken.companyUuid 
  };
}; 