import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCogs, FaShieldAlt } from 'react-icons/fa';
import { RiComputerLine, RiSmartphoneLine, RiTerminalBoxLine, RiShieldKeyholeFill, RiShieldFlashFill, RiShieldKeyholeLine } from 'react-icons/ri';
import { useLanguage } from '../LanguageContext';
import axios from '../utils/axios';
import { checkAuth } from '../utils/auth';
import Footer from './Footer';
import './Home.css';

const Home = () => {
  const [userName, setUserName] = useState('');
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const { translate } = useLanguage();
  const navigate = useNavigate();

  // Função para extrair o primeiro nome
  const getFirstName = (fullName) => {
    if (!fullName) return '';
    return fullName.split(' ')[0];
  };

  const getApplicationIcon = (type) => {
    switch (type) {
      case 'web application':
        return (
          <div className="icon-container web-app">
            <RiComputerLine />
          </div>
        );
      case 'mobile application':
        return (
          <div className="icon-container mobile-app">
            <RiSmartphoneLine />
          </div>
        );
      case 'api':
        return (
          <div className="icon-container api-app">
            <RiTerminalBoxLine />
          </div>
        );
      default:
        return (
          <div className="icon-container web-app">
            <RiComputerLine />
          </div>
        );
    }
  };

  const getFaviconUrl = (host) => {
    try {
      const url = new URL(host);
      return `https://www.google.com/s2/favicons?domain=${url.hostname}&sz=64`;
    } catch (error) {
      return `https://www.google.com/s2/favicons?domain=${host}&sz=64`;
    }
  };

  const handleFaviconError = (e) => {
    e.target.style.display = 'none';
  };

  const getOperationIcon = (type) => {
    const getTooltipText = (type) => {
      switch (type) {
        case 'black-box':
          return translate('applications.tooltips.blackBox');
        case 'grey-box':
          return translate('applications.tooltips.greyBox');
        case 'white-box':
          return translate('applications.tooltips.whiteBox');
        default:
          return '';
      }
    };

    switch (type) {
      case 'black-box':
        return (
          <div className="operation-icon-container black-box" title={getTooltipText(type)}>
            <RiShieldKeyholeFill />
          </div>
        );
      case 'grey-box':
        return (
          <div className="operation-icon-container grey-box" title={getTooltipText(type)}>
            <RiShieldFlashFill />
          </div>
        );
      case 'white-box':
        return (
          <div className="operation-icon-container white-box" title={getTooltipText(type)}>
            <RiShieldKeyholeLine />
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const storedUserName = localStorage.getItem('userName');
    if (storedUserName) {
      setUserName(getFirstName(storedUserName));
    }
  }, []);

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const { isAuthenticated, companyUuid } = checkAuth();

        if (!isAuthenticated) {
          navigate('/auth/signin');
          return;
        }

        const response = await axios.get(`/applications/company/${companyUuid}`);
        
        if (response.data && Array.isArray(response.data)) {
          setApplications(response.data);
        } else if (response.data && Array.isArray(response.data.applications)) {
          setApplications(response.data.applications);
        }
        
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar aplicações:', error);
        setLoading(false);
      }
    };

    fetchApplications();
  }, [navigate]);

  const services = [
    {
      icon: <FaCogs />,
      name: translate('home.services.applications.name'),
      description: translate('home.services.applications.description'),
      link: '/applications'
    },
    {
      icon: <FaShieldAlt />,
      name: translate('home.services.monitoring.name'),
      description: translate('home.services.monitoring.description'),
      link: '/monitoring/dashboard'
    }
  ];

  const handleAddApplication = () => {
    navigate('/applications');
  };

  return (
    <div className="home-container">
      <h1 className="greeting">{translate('home.welcome', { userName })}</h1>
      <h1 className="greeting" style={{ paddingTop: '0px' }}>{translate('home.help')}</h1>
      <div className="widgets-container">
        <div className="widget services">
          <h2>{translate('home.solutions')}</h2>
          <div className="services-grid">
            {services.map((service, index) => (
              <a key={index} href={service.link} className="service-card">
                <div className="service-icon">{service.icon}</div>
                <h3>{service.name}</h3>
                <p>{service.description}</p>
              </a>
            ))}
          </div>
        </div>

        <div className="widget applications">
          <h2>{translate('home.applications')}</h2>
          <div className="applications-header">
            <button className="add-app" onClick={handleAddApplication}>
              {translate('home.addApplication')}
            </button>
          </div>
          <div className="applications-table">
            <table>
              <tbody>
                {applications.slice(0, 3).map((app, index) => (
                  <tr 
                    key={index}
                    onClick={() => navigate('/applications')}
                    style={{ 
                      cursor: 'pointer',
                      transition: 'background-color 0.2s ease',
                    }}
                    onMouseEnter={(e) => e.target.style.backgroundColor = 'var(--hover-color)'}
                    onMouseLeave={(e) => e.target.style.backgroundColor = 'transparent'}
                  >
                    <td>
                      <div className="application-type">
                        {getApplicationIcon(app.application_type)}
                        <span>{translate(`applications.types.${app.application_type.split(' ')[0].toLowerCase()}`)}</span>
                      </div>
                    </td>
                    <td>
                      <div className="host-container">
                        <img 
                          src={getFaviconUrl(app.host)} 
                          alt="" 
                          className="host-favicon"
                          onError={handleFaviconError}
                        />
                        <span>{app.host}</span>
                      </div>
                    </td>
                    <td>
                      <div className="operation-type">
                        {getOperationIcon(app.operation_type)}
                        <span>{translate(`applications.operations.${app.operation_type.replace('-', '')}`)}</span>
                      </div>
                    </td>
                    <td>
                      <span className={`status ${app.status?.toLowerCase()}`}>
                        {translate(`applications.status.${app.status?.toLowerCase()}`)}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {applications.length > 3 && (
              <div style={{ textAlign: 'right', marginTop: '10px', marginRight: '10px' }}>
                <button 
                  onClick={() => navigate('/applications')}
                  style={{
                    background: 'none',
                    border: 'none',
                    color: 'var(--text-secondary)',
                    cursor: 'pointer',
                    fontSize: '13px',
                    padding: '4px 8px',
                    opacity: 0.8,
                    transition: 'opacity 0.2s ease',
                    textDecoration: 'none'
                  }}
                  onMouseEnter={(e) => e.target.style.opacity = '1'}
                  onMouseLeave={(e) => e.target.style.opacity = '0.8'}
                >
                  {translate('common.view_all')}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home; 