import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <p>© 2025, Wehack Serviços de Cyber Security LTDA.</p>
      <div className="footer-links">
        <a href="/privacy">Privacidade</a>
        <a href="/terms">Termos</a>
        <a href="/cookie-preferences">Preferências de cookies</a>
      </div>
    </footer>
  );
};

export default Footer; 