import React from 'react';
import { Link } from 'react-router-dom';
import { FaChartLine, FaFileAlt, FaLightbulb } from 'react-icons/fa';
import { useLanguage } from '../LanguageContext';
import './Sidebar.css';

const Sidebar = ({ activeItem }) => {
  const { translate } = useLanguage();

  const menuItems = [
    {
      name: translate('monitoring.dashboard'),
      icon: <FaChartLine />,
      link: '/monitoring/dashboard'
    },
    {
      name: translate('monitoring.reports'),
      icon: <FaFileAlt />,
      link: '/monitoring/reports'
    },
    {
      name: translate('monitoring.insights'),
      icon: <FaLightbulb />,
      link: '/monitoring/insights'
    }
  ];

  return (
    <aside className="sidebar">
      <nav className="sidebar-nav">
        {menuItems.map((item, index) => (
          <Link
            key={index}
            to={item.link}
            className={`nav-item ${item.name === activeItem ? 'active' : ''}`}
          >
            <span className="icon">{item.icon}</span>
            <span className="label">{item.name}</span>
          </Link>
        ))}
      </nav>
    </aside>
  );
};

export default Sidebar;
