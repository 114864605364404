import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../utils/axios';
import { useLanguage } from '../LanguageContext';
import './Login.css';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { translate } = useLanguage();

  // Extrair o token da URL
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  useEffect(() => {
    console.log('Token recebido:', token);
    if (!token) {
      console.log('Nenhum token encontrado, redirecionando para login');
      navigate('/auth/signin');
    }
  }, [token, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    if (password !== confirmPassword) {
      setErrorMessage('As senhas não coincidem');
      return;
    }

    try {
      console.log('Enviando requisição de redefinição de senha...');
      const response = await axios.post('/auth/reset-password', {
        token,
        newPassword: password
      });

      console.log('Senha redefinida com sucesso');
      setSuccessMessage('Senha redefinida com sucesso!');
      setTimeout(() => {
        navigate('/auth/signin');
      }, 2000);
    } catch (error) {
      console.error('Erro ao redefinir senha:', error);
      setErrorMessage(error.response?.data?.error || 'Erro ao redefinir senha');
    }
  };

  if (!token) {
    return null;
  }

  return (
    <div className="login-page">
      <div className="image-container">
        <img src="/assets/login.jpg" alt="Imagem de Fundo" className="background-image" />
      </div>
      <div className="login-container">
        <div className="login-card">
          <img src="/assets/wehack-logo.png" alt="Wehack Logo" className="logo" />
          <h2>Redefinir Senha</h2>
          {errorMessage && <div className="error-toast">{errorMessage}</div>}
          {successMessage && <div className="success-toast">{successMessage}</div>}
          <form className="login-form" onSubmit={handleSubmit}>
            <div className="input-group">
              <input
                type="password"
                placeholder="Nova senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                minLength="6"
              />
            </div>
            <div className="input-group">
              <input
                type="password"
                placeholder="Confirme a nova senha"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                minLength="6"
              />
            </div>
            <button type="submit" className="login-button">Redefinir Senha</button>
          </form>
          <footer>© 2025 Wehack. Todos os direitos reservados.</footer>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword; 