import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCogs, FaShieldAlt, FaBug, FaLock, FaCloud, FaMobile, FaFileAlt, FaUser, FaSignOutAlt } from 'react-icons/fa';
import './Navbar.css';
import { useLanguage } from '../LanguageContext';

const Navbar = () => {
  const navigate = useNavigate();
  const companyName = localStorage.getItem('companyName') || 'Empresa';
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { translate } = useLanguage();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogoClick = () => {
    navigate('/home');
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const solutions = [
    {
      icon: <FaCogs />,
      name: translate('navbar.solutions.applications.name'),
      description: translate('navbar.solutions.applications.description'),
      link: '/applications'
    },
    {
      icon: <FaShieldAlt />,
      name: translate('navbar.solutions.monitoring.name'),
      description: translate('navbar.solutions.monitoring.description'),
      link: '/monitoring/dashboard'
    }
  ];

  const handleLogout = () => {
    localStorage.clear();
    navigate('/auth/signin');
  };

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <img 
          src="/assets/wehack-logo-white.png" 
          alt="Wehack Logo" 
          className="navbar-logo" 
          onClick={handleLogoClick}
          style={{ cursor: 'pointer' }}
        />
        <div className="menu-container" onMouseEnter={() => setMenuOpen(true)} onMouseLeave={() => setMenuOpen(false)}>
          <img 
            src="/assets/menu.png" 
            alt="Menu" 
            className="navbar-menu"
          />
          {menuOpen && (
            <div className="menu-dropdown">
              <h2>{translate('navbar.solutions.title')}</h2>
              <div className="solutions-grid">
                {solutions.map((solution, index) => (
                  <a key={index} href={solution.link} className="solution-card">
                    <div className="solution-icon">{solution.icon}</div>
                    <h3>{solution.name}</h3>
                    <p>{solution.description}</p>
                  </a>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="navbar-right" ref={dropdownRef}>
        <div className="navbar-user" onClick={toggleDropdown}>
          {companyName}
          <span className={`dropdown-icon ${dropdownOpen ? 'open' : ''}`}>▼</span>
        </div>
        {dropdownOpen && (
          <div className="company-dropdown">
            <div className="dropdown-actions">
              <button className="dropdown-action-btn" onClick={() => navigate('/profile')}>
                <FaUser /> {translate('navbar.profile')}
              </button>
              <button className="dropdown-action-btn logout" onClick={handleLogout}>
                <FaSignOutAlt /> {translate('navbar.logout')}
              </button>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
