import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { checkAuth } from './auth';

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  console.log('PrivateRoute - Rota atual:', location.pathname);
  
  const auth = checkAuth();
  console.log('PrivateRoute - Resultado da autenticação:', {
    isAuthenticated: auth.isAuthenticated,
    hasToken: !!auth.token,
    hasCompanyUuid: !!auth.companyUuid
  });

  useEffect(() => {
    console.log('PrivateRoute - Montando componente');
    return () => {
      console.log('PrivateRoute - Desmontando componente');
    };
  }, []);

  if (!auth.isAuthenticated) {
    console.log('PrivateRoute - Usuário não autenticado, redirecionando para login');
    return <Navigate to="/auth/signin" replace state={{ from: location }} />;
  }

  console.log('PrivateRoute - Usuário autenticado, renderizando componente');
  return children;
};

export default PrivateRoute; 