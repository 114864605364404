import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { checkAuth } from '../utils/auth';
import { useLanguage } from '../LanguageContext';
import Sidebar from './Sidebar';
import './ReportDetails.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const ReportDetails = () => {
  const { translate } = useLanguage();
  const { reportId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [report, setReport] = useState(null);
  const [activeItem, setActiveItem] = useState(translate('monitoring.reports'));
  const [companyUsers, setCompanyUsers] = useState([]);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const contentRef = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [newStatus, setNewStatus] = useState('');
  const [newNote, setNewNote] = useState('');
  const [movements, setMovements] = useState([]);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const selectRef = useRef(null);
  const [isStatusOpen, setIsStatusOpen] = useState(false);

  const statusOptions = [
    { value: 'New', icon: 'fas fa-star' },
    { value: 'Under Review', icon: 'fas fa-search' },
    { value: 'Confirmed', icon: 'fas fa-check-circle' },
    { value: 'Awaiting Fix', icon: 'fas fa-tools' },
    { value: 'In Mitigation', icon: 'fas fa-shield-alt' },
    { value: 'Awaiting Retest', icon: 'fas fa-sync' },
    { value: 'Retest Done', icon: 'fas fa-clipboard-check' },
    { value: 'Fixed', icon: 'fas fa-check-double' },
    { value: 'Closed', icon: 'fas fa-lock' }
  ];

  const getStatusIcon = (status) => {
    const option = statusOptions.find(opt => opt.value === status);
    return option ? option.icon : 'fas fa-question';
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        
        const { isAuthenticated, token, companyUuid } = checkAuth();
        
        if (!isAuthenticated || !token || !companyUuid) {
          navigate('/auth/signin');
          return;
        }

        console.log('Buscando dados do relatório e usuários...');
        console.log('CompanyUuid:', companyUuid);

        // Buscar detalhes do relatório
        const reportResponse = await axios.get(
          `/api/reports/${reportId}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        // Buscar usuários da empresa
        const usersResponse = await axios.get(
          `/api/company/${companyUuid}/users`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        console.log('Resposta dos usuários:', usersResponse.data);
        console.log('Usuários encontrados:', usersResponse.data.users);

        setReport(reportResponse.data);
        setCompanyUsers(usersResponse.data.users || []);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
        setError(error.response?.data?.error || 'Erro ao carregar os dados');
        if (error.response?.status === 401) {
          navigate('/auth/signin');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [reportId, navigate]);

  useEffect(() => {
    const fetchMovements = async () => {
      try {
        const { token } = checkAuth();
        const response = await axios.get(
          `/api/reports/${reportId}/movements`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setMovements(response.data.movements);
      } catch (error) {
        console.error('Erro ao buscar movimentações:', error);
      }
    };

    fetchMovements();
  }, [reportId]);

  useEffect(() => {
    const content = contentRef.current;
    if (!content) return;

    const handleScroll = () => {
      const scrollPosition = content.scrollTop;
      console.log('Scroll position:', scrollPosition); // Para debug
      const scrolled = scrollPosition > 30;
      console.log('Is scrolled:', scrolled); // Para verificar o estado
      setIsScrolled(scrolled);
    };

    // Adiciona o evento de scroll ao elemento .content
    content.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      if (content) {
        content.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const handleResponsibleChange = async (selectedUser) => {
    try {
      const { token } = checkAuth();

      // Fecha o dropdown imediatamente
      setIsSelectOpen(false);

      const response = await axios.patch(
        `/api/reports/${reportId}`,
        { 
          responsible: selectedUser.name,
          type: 'RESPONSIBLE_CHANGE',
          notes: `Responsável alterado para ${selectedUser.name}`
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      // Atualiza o relatório
      setReport(response.data.report);
      
      // Busca as movimentações atualizadas
      const movementsResponse = await axios.get(
        `/api/reports/${reportId}/movements`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      
      // Atualiza as movimentações com os dados mais recentes
      setMovements(movementsResponse.data.movements);

    } catch (error) {
      console.error('Erro ao alterar responsável:', error);
      alert(`Erro ao alterar responsável: ${error.response?.data?.error || error.message}`);
    }
  };

  const getImpactColor = (impact) => {
    const numericImpact = parseFloat(impact);
    let criticality = "low";
    
    if (numericImpact >= 9.0) criticality = "critical";
    else if (numericImpact >= 7.0) criticality = "high";
    else if (numericImpact >= 4.0) criticality = "medium";
    
    switch (criticality) {
      case "critical": return '#dc3545';
      case "high": return '#fd7e14';
      case "medium": return '#ffc107';
      case "low": return '#28a745';
      default: return '#6c757d';
    }
  };

  const getImpactText = (impact) => {
    if (!impact) return '';
    const numericImpact = parseFloat(impact);
    
    let criticality = 'low';
    if (numericImpact >= 9.0) criticality = 'critical';
    else if (numericImpact >= 7.0) criticality = 'high';
    else if (numericImpact >= 4.0) criticality = 'medium';
    
    return translate(`reports.filters.impact_level.${criticality}`);
  };

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  const handleScrollToTop = () => {
    const content = contentRef.current;
    if (content) {
      content.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  const handleSave = async () => {
    try {
      const { token } = checkAuth();

      if (!newStatus) {
        alert('Por favor, selecione um status');
        return;
      }

      const response = await axios.patch(
        `/api/reports/${reportId}`,
        { 
          status: newStatus,
          notes: newNote 
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      // Atualiza o estado local do relatório
      setReport(response.data.report);
      
      // Busca as movimentações atualizadas
      const movementsResponse = await axios.get(
        `/api/reports/${reportId}/movements`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      
      // Atualiza as movimentações com os dados mais recentes
      setMovements(movementsResponse.data.movements);

      setIsPopupOpen(false);
      setNewNote('');
      setNewStatus('');
    } catch (error) {
      console.error('Erro ao salvar alterações:', error);
      alert(`Erro ao salvar as alterações: ${error.response?.data?.error || error.message}`);
    }
  };

  const handleSelectClick = (event) => {
    event.stopPropagation(); // Previne a propagação do evento
    const rect = event.currentTarget.getBoundingClientRect();
    setDropdownPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX
    });
    setIsSelectOpen(!isSelectOpen);
  };

  const getCriticality = (impact) => {
    const numericImpact = parseFloat(impact);
    if (numericImpact >= 9.0 && numericImpact <= 10.0) return "critical";
    if (numericImpact >= 7.0 && numericImpact < 9.0) return "high";
    if (numericImpact >= 4.0 && numericImpact < 7.0) return "medium";
    return "low";
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isSelectOpen && !event.target.closest('.user-select-dropdown')) {
        setIsSelectOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isSelectOpen]);

  if (loading) {
    return (
      <div className="monitoring-container">
        <Sidebar activeItem={activeItem} />
        <div className="content">
          <div className="report-details-container">
            <div className="loading">
              <div className="spinner"></div>
              <span>Carregando detalhes...</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="monitoring-container">
        <Sidebar activeItem={activeItem} />
        <div className="content">
          <div className="report-details-container">
            <div className="error-message">
              <p>{error}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="monitoring-container">
      <Sidebar activeItem={activeItem} />
      <div className="content" ref={contentRef}>
        <div className="report-details-container">
          <div className={`sticky-header ${isScrolled ? 'scrolled' : ''}`}>
            <div className="report-title">
              <h2>{report?.name}</h2>
            </div>

            <div className="quick-info-grid">
              <div className="quick-info-row">
                <div className="quick-info-card date-card">
                  <div className="card-content">
                    <div className="card-icon">
                      <i className="fas fa-calendar-alt"></i>
                    </div>
                    <div className="card-text">
                      <label>{translate('report_details.report_date')}</label>
                      <div className="value" data-tooltip={report?.created_at ? new Date(report.created_at).toLocaleDateString('pt-BR') : 'Data não disponível'}>
                        {report?.created_at ? new Date(report.created_at).toLocaleDateString('pt-BR') : 'Data não disponível'}
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="quick-info-card category-card">
                  <div className="card-content">
                    <div className="card-icon">
                      <i className="fas fa-tag"></i>
                    </div>
                    <div className="card-text">
                      <label>{translate('report_details.category')}</label>
                      <div className="value" data-tooltip={report?.type}>
                        {report?.type}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="quick-info-card app-card">
                  <div className="card-content">
                    <div className="card-icon">
                      <i className="fas fa-laptop-code"></i>
                    </div>
                    <div className="card-text">
                      <label>{translate('report_details.application')}</label>
                      <div className="value" data-tooltip={report?.application?.host}>
                        {report?.application ? (
                          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <img
                              src={`https://www.google.com/s2/favicons?domain=${report.application.host}&sz=32`}
                              alt=""
                              style={{ width: '16px', height: '16px' }}
                              onError={(e) => {
                                e.target.style.display = 'none';
                              }}
                            />
                            <span>{report.application.host}</span>
                          </div>
                        ) : (
                          'N/A'
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="quick-info-card id-card">
                  <div className="card-content">
                    <div className="card-icon">
                      <i className="fas fa-fingerprint"></i>
                    </div>
                    <div className="card-text">
                      <label>{translate('report_details.identifier')}</label>
                      <div className="value" data-tooltip={`#${report?.id?.toUpperCase()}`}>
                        #{report?.id?.slice(0, 8).toUpperCase()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="quick-info-row">
                <div className="quick-info-card status-card">
                  <div className="card-content">
                    <div className="card-icon">
                      <i className={`${getStatusIcon(report?.status)} icon ${report?.status?.toLowerCase()?.replace(/ /g, '-')}`}></i>
                    </div>
                    <div className="card-text">
                      <label>{translate('report_details.status')}</label>
                      <div className="value">
                        {translate(`reports.filters.status.${report?.status?.toLowerCase()?.replace(/ /g, '_')}`)}
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`quick-info-card responsible-card ${isSelectOpen ? 'expanded' : ''}`}>
                  <div className="card-content">
                    <div className="card-icon">
                      <i className="fas fa-user-shield"></i>
                    </div>
                    <div className="card-text">
                      <label>{translate('report_details.responsible')}</label>
                      <div className="custom-select" ref={selectRef}>
                        <div 
                          className={`select-selected ${isSelectOpen ? 'expanded' : ''}`}
                          onClick={handleSelectClick}
                        >
                          {report?.responsible ? (
                            <div className="user-option">
                              <div className="user-initials">{getInitials(report.responsible)}</div>
                              <div className="user-name">{report.responsible}</div>
                            </div>
                          ) : (
                            <div className="user-option">
                              <div className="user-name">{translate('report_details.select_responsible')}</div>
                            </div>
                          )}
                        </div>
                        {isSelectOpen && companyUsers.length > 0 && (
                          <div className="select-options">
                            {companyUsers.map(user => (
                              <div
                                key={user.uuid}
                                className="user-option"
                                onClick={() => handleResponsibleChange(user)}
                              >
                                <div className="user-initials">{getInitials(user.name)}</div>
                                <div className="user-name">{user.name}</div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="quick-info-card criticality-card">
                  <div className="card-content">
                    <div className="card-icon">
                      <i className={`fas fa-exclamation-triangle icon ${getCriticality(report?.impact)}`}></i>
                    </div>
                    <div className="card-text">
                      <label>{translate('report_details.criticality')}</label>
                      <div className="value">
                        <span 
                          className="impact-badge"
                          style={{ backgroundColor: getImpactColor(report?.impact) }}
                          data-tooltip={getImpactText(report?.impact)}
                        >
                          {getImpactText(report?.impact)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`main-content-wrapper ${isScrolled ? 'header-scrolled' : ''}`}>
            <div className="details-container">
              <div className="main-content">
                <h3>{translate('report_details.vulnerability_details')}</h3>
                <p className="description">{report?.details}</p>
                
                <h3>{translate('report_details.reproduction_steps')}</h3>
                <p className="description">{report?.reproduction_steps}</p>

                <h3>{translate('report_details.solutions')}</h3>
                <p className="description">{report?.solutions || translate('report_details.no_solutions')}</p>
              </div>

              <div>
                <button 
                  className="management-button"
                  onClick={() => setIsPopupOpen(true)}
                >
                  <i className="fas fa-cog"></i>
                  {translate('report_details.manage_report')}
                </button>

                <div className="timeline">
                  <div className="timeline-header">
                    <h3>{translate('report_details.movements')}</h3>
                  </div>
                  {movements.map((movement, index) => (
                    <div className="timeline-item" key={index}>
                      <div className="time">
                        <i className="far fa-clock"></i>
                        {new Date(movement.createdAt).toLocaleString('pt-BR', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit'
                        })}
                      </div>
                      <div className={`event ${movement.type === 'STATUS_CHANGE' ? movement.event.split('Status alterado para ')[1]?.toLowerCase()?.replace(/ /g, '-') : movement.type === 'RESPONSIBLE_CHANGE' ? 'responsible-change' : 'note'}`}>
                        {movement.type === 'STATUS_CHANGE' ? (
                          <>
                            <i className={getStatusIcon(movement.event.split('Status alterado para ')[1])}></i>
                            <div className="event-text">
                              {translate('report_details.movements_timeline.status_change')} <strong>{translate(`reports.filters.status.${movement.event.split('Status alterado para ')[1]?.toLowerCase()?.replace(/ /g, '_')}`)}</strong>
                            </div>
                          </>
                        ) : movement.type === 'RESPONSIBLE_CHANGE' ? (
                          <>
                            <i className="fas fa-user-edit"></i>
                            <div className="event-text">
                              {translate('report_details.movements_timeline.responsible_change')} <strong>{movement.event.split('Responsável alterado para ')[1]}</strong>
                            </div>
                          </>
                        ) : (
                          <>
                            <i className="fas fa-comment"></i>
                            <div className="event-text">
                              {translate('report_details.movements_timeline.note')}
                            </div>
                          </>
                        )}
                        <div className="event-author">
                          <span>•</span>
                          <strong>{translate('report_details.movements_timeline.by')} {movement.userName}</strong>
                        </div>
                      </div>
                      {movement.note && (
                        <div className="note">
                          <i className="fas fa-quote-left"></i>
                          {movement.note}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {isPopupOpen && (
              <div className="popup-overlay">
                <div className="popup-content">
                  <div className="popup-header">
                    <h2>{translate('report_details.manage_report')}</h2>
                    <button 
                      className="close-button"
                      onClick={() => setIsPopupOpen(false)}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </div>

                  <div className="custom-status-select">
                    <div 
                      className="status-selected"
                      onClick={() => setIsStatusOpen(!isStatusOpen)}
                    >
                      {newStatus ? (
                        <div className={`status-option ${newStatus.toLowerCase().replace(/ /g, '-')}`}>
                          <i className={getStatusIcon(newStatus)}></i>
                          <span>{translate(`reports.filters.status.${newStatus.toLowerCase().replace(/ /g, '_')}`)}</span>
                        </div>
                      ) : (
                        <span>{translate('report_details.select_status')}</span>
                      )}
                    </div>
                    {isStatusOpen && (
                      <div className="status-options">
                        {statusOptions.map(status => (
                          <div
                            key={status.value}
                            className={`status-option ${status.value.toLowerCase().replace(/ /g, '-')}`}
                            onClick={() => {
                              setNewStatus(status.value);
                              setIsStatusOpen(false);
                            }}
                          >
                            <i className={status.icon}></i>
                            <span>{translate(`reports.filters.status.${status.value.toLowerCase().replace(/ /g, '_')}`)}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <textarea
                    className="notes-textarea"
                    value={newNote}
                    onChange={(e) => setNewNote(e.target.value)}
                    placeholder={translate('report_details.add_note')}
                  />

                  <button 
                    className="save-button"
                    onClick={handleSave}
                  >
                    {translate('report_details.save_changes')}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div 
          className={`scroll-to-top ${isScrolled ? 'visible' : ''}`}
          onClick={handleScrollToTop}
        >
          <i className="fas fa-chevron-up"></i>
        </div>
      </div>
    </div>
  );
};

export default ReportDetails; 